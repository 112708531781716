import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { Portal } from "@material-ui/core";
import classNames from "classnames";
import { t } from "i18n-js";
import { linkifyTrixContent } from "@circle-react/helpers/actionTextHelpers";
import { TrixEditorFeatureFlagContextProvider } from "@circle-react-shared/TrixEditor/TrixEditorFeatureFlagContext";
import { useMdScreenMediaQuery } from "../../../hooks/useMediaQuery";
import { If } from "../If";
import { Autolink } from "./Autolink";
import { BlockSelector } from "./BlockSelector";
import { HyperlinkTooltips } from "./HyperlinkTooltips";
import { InlineEmojiSelector } from "./InlineEmojiSelector";
import { InlineLinkForm } from "./InlineLinkForm";
import { InlineModalSelector } from "./InlineModalSelector";
import { InlineToolbar } from "./InlineToolbar";
import { Toolbar } from "./Toolbar";
import { TrixEditorContext } from "./TrixEditorContext";
import { TrixOverrides } from "./TrixOverrides";
import type { TrixEditorRefObject } from "./isTrixEditorRefObject";
import { isTrixEditorRefObject } from "./isTrixEditorRefObject";
import { useAttachmentOverlay } from "./useAttachmentOverlay";
import { useAttachments } from "./useAttachments";
import { useAutoExpandEditor } from "./useAutoExpandEditor";
import { useDetectOutsideElementClick } from "./useDetectOutsideElementClick";
import { TrixEditorModeContext, useManageEditorMode } from "./useEditorMode";
import { useMentions } from "./useMentions";
import { usePreventAccidentalPageLeaveIfTrixDirty } from "./usePreventAccidentalPageLeaveIfTrixDirty";
import "./styles.scss";

export { isTrixEditorRefObject };
export type { TrixEditorRefObject };

// Placeholder props so new components created in TSX that use TrixEditor
// don't fail type checking
export interface TrixEditorProps {
  [key: string]: any;
}

TrixOverrides.perform();

/** @deprecated Please use TipTap editor instead for new components */
const Component = (props: TrixEditorProps) => {
  const {
    id,
    name,
    dataIsPrivateSpace,
    dataSpaceId,
    placeholder,
    preventAccidentalLeave = true,
    tabIndex,
    className = "",
    toolbarPortalId,
    forwardedRef,
    editorContainerClassName = "",
  } = props;
  const editorRef = useRef<HTMLElement>(null);
  const [editorElement, setEditorElement] = useState<HTMLElement | null>(null);
  const [formPlaceholder, setFormPlaceholder] = useState(placeholder);
  const containerRef = useRef<HTMLDivElement>(null);
  const trixPlaceholder = t("post_editor_placeholder");
  const trixMobilePlaceholder = t("post_editor_placeholder_mobile");
  const editorModeContext = useManageEditorMode(editorRef.current);

  let value: any, onChange: any;
  // eslint-disable-next-line no-prototype-builtins -- Added before disable comments where required
  const isControllerInput = props.hasOwnProperty("value");
  if (isControllerInput) {
    value = props.value;
    onChange = props.onChange;
    if (!onChange) {
      console.error("value provided without onChange");
    }
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks -- Added before disable comments where obligatory
    const valueState = useState(props.defaultValue || "");
    const setValue = valueState[1];
    value = valueState[0];
    onChange = (event: any) => {
      setValue(event.target.innerHTML);
    };
  }
  const { insertMention, shouldInsert, removeMentions } = useMentions(
    editorRef,
    props,
  );

  useEffect(() => {
    if (!editorRef.current || !forwardedRef) {
      return;
    }
    forwardedRef.current = {
      editorRef,
      focus: () => {
        if (!editorRef.current) {
          return;
        }
        editorRef.current.focus();
      },
      insertMention,
      shouldInsert,
      removeMentions,
    };
  }, [
    editorRef.current,
    forwardedRef,
    insertMention,
    shouldInsert,
    removeMentions,
  ]);

  useAttachments(editorRef);

  const isMdScreen = useMdScreenMediaQuery();

  const outsideClickDetectionCondition = (event: any) => {
    const { showInlineLinkForm, showInlineModalSelector } = editorModeContext;
    const isEditorUsingPortal =
      showInlineLinkForm() || showInlineModalSelector();

    const isEditorToolbarUsingPortal = toolbarPortalId;

    if (!isMdScreen || isEditorUsingPortal) {
      return false;
    }

    if (isEditorToolbarUsingPortal) {
      const toolbarPortal = document.getElementById(toolbarPortalId);
      if (toolbarPortal?.contains(event.target)) {
        return false;
      }
    }

    return (
      document.contains(event.target) &&
      (!containerRef.current?.contains(event.target) ||
        editorRef.current?.contains(event.target))
    );
  };

  useDetectOutsideElementClick(
    containerRef.current,
    editorModeContext.resetEditorMode,
    [],
    outsideClickDetectionCondition,
  );

  useEffect(() => {
    if (placeholder) {
      return;
    }
    const newPlaceHolder = isMdScreen ? trixPlaceholder : trixMobilePlaceholder;
    setFormPlaceholder(newPlaceHolder);
  }, [placeholder, isMdScreen]);

  useEffect(() => {
    setEditorElement(editorRef.current);
  }, [editorRef]);

  useEffect(() => {
    if (!editorRef.current) {
      return;
    }

    if ("toolbarElement" in editorRef.current) {
      const toolbarElement: any = editorRef.current.toolbarElement;
      toolbarElement.parentNode.removeChild(toolbarElement);
    }
  }, [editorRef]);

  // Todo: Remove this later
  const getFormElement = () => editorElement?.closest("form");
  if (preventAccidentalLeave) {
    // eslint-disable-next-line react-hooks/rules-of-hooks -- Added before disable comments where obligatory
    usePreventAccidentalPageLeaveIfTrixDirty(value, getFormElement);
  }

  useAttachmentOverlay(editorRef);

  useEffect(() => {
    if (!editorElement) {
      return;
    }
    editorElement.addEventListener("trix-change", onChange);
    return () => {
      editorElement.removeEventListener("trix-change", onChange);
    };
  }, [editorElement, isControllerInput ? onChange : null]);

  const isExpanded = useAutoExpandEditor(editorRef, containerRef);

  const linkifiedValue = useMemo(
    () => value && linkifyTrixContent(value),
    [value],
  );

  return (
    <TrixEditorContext.Provider value={editorElement}>
      <TrixEditorModeContext.Provider value={editorModeContext}>
        <div
          className={classNames(
            "react-trix-editor-container",
            editorContainerClassName,
          )}
          ref={containerRef}
        >
          <If condition={!!editorElement}>
            <InlineToolbar />
            <InlineLinkForm />
            <InlineEmojiSelector />
            <Autolink />
            <InlineModalSelector />
            <BlockSelector variant="inline" />
            <BlockSelector variant="modal" />
            <HyperlinkTooltips />
          </If>
          <div
            className={classNames(
              "trix-editor-textbox",
              "form-control",
              className,
              {
                expanded: isExpanded,
              },
            )}
          >
            <input type="hidden" id={id} name={name} value={linkifiedValue} />
            <trix-editor
              ref={editorRef}
              input={id}
              placeholder={formPlaceholder}
              // TODO: Correct the behaviour of mention to avoid using data attributes.
              data-is-private-space={dataIsPrivateSpace}
              data-space-id={dataSpaceId}
              tabIndex={tabIndex || ""}
            />
          </div>
          <If condition={!!editorElement}>
            <Portal
              container={() => {
                if (toolbarPortalId) {
                  return document.getElementById(toolbarPortalId);
                }
                return document.body;
              }}
              disablePortal={!toolbarPortalId}
            >
              <div
                className={classNames("comment-box__bar", {
                  expanded: isExpanded,
                })}
              >
                <Toolbar />
              </div>
            </Portal>
          </If>
        </div>
      </TrixEditorModeContext.Provider>
    </TrixEditorContext.Provider>
  );
};

/** @deprecated Please use TipTap editor instead for new components */
export const TrixEditor = forwardRef<TrixEditorRefObject, TrixEditorProps>(
  (props: TrixEditorProps, ref) => (
    <TrixEditorFeatureFlagContextProvider value={props.featureFlags}>
      <Component {...props} forwardedRef={ref} />
    </TrixEditorFeatureFlagContextProvider>
  ),
);

TrixEditor.displayName = "TrixEditor";
